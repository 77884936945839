import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Landing } from '../Landing';
import { VideoUploader } from '../VideoUploader';
import { SocialMedia } from '../SocialMedia';
import { Reports } from '../Reports';
import { Schedule } from '../Schedule';
import { TrendSetters } from '../TrendSetter';
import { Variations } from '../Variations';

function AuthenticatedApp() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Landing />} >
                    <Route path="" element={<Reports />} />
                    <Route path="VideoUploader" element={<VideoUploader />} />
                    <Route path="Reports" element={<Reports />} />
                    <Route path="Schedule" element={<Schedule />} />
                    <Route path="TrendSetters" element={<TrendSetters />} />
                    <Route path="SocialMedia" element={<SocialMedia />} />
                    <Route path="Variants" element={<Variations />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export { AuthenticatedApp };