import React, { useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './styles.css';

function Schedule() {
    const [date, setDate] = useState(new Date());
    const events = [
        { date: new Date(2025, 2, 10), title: 'Event 1' },
        { date: new Date(2025, 2, 15), title: 'Event 2' },
        { date: new Date(2025, 2, 20), title: 'Event 3' },
    ];

    const onChange = (date) => {
        setDate(date);
    };

    const renderEvents = (date) => {
        return events
            .filter(event => event.date.toDateString() === date.toDateString())
            .map((event, index) => (
                <div key={index} className="event">
                    {event.title}
                </div>
            ));
    };

    return (
        <div className="schedule">
            <h1>Schedule</h1>
            <Calendar
                onChange={onChange}
                value={date}
                tileContent={({ date, view }) => view === 'month' && renderEvents(date)}
            />
            <div className="events">
                <h2>Events on {date.toDateString()}</h2>
                {renderEvents(date)}
            </div>
        </div>
    );
}

export { Schedule };