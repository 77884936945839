import { initializeApp } from "firebase/app";
import { GoogleAuthProvider, signInWithPopup, getAuth } from 'firebase/auth';
import React from 'react';

const firebaseConfig = {
  apiKey: "AIzaSyA9j1ofXpRQcaLLqAQo0dxlBjQHaAbRAmU",
  authDomain: "mcapn-2ebe5.firebaseapp.com",
  projectId: "mcapn-2ebe5",
  storageBucket: "mcapn-2ebe5.appspot.com",
  messagingSenderId: "837395620806",
  appId: "1:837395620806:web:9b66921022c11283ae48c7",
  measurementId: "G-CZ92MZHCWL"
};

initializeApp(firebaseConfig);

function SubscribeUserAuthEvent(setUser) {
  React.useEffect(() => {
    const unsubscribe = getAuth().onAuthStateChanged((user) => { // detaching the listener
        if (user) {
            // ...your code to handle authenticated users. 
            setUser(user);
        } else {
            // No user is signed in...code to handle unauthenticated users. 
            setUser(null);
        }
    });
      return () => unsubscribe(); // unsubscribing from the listener when the component is unmounting. 
    }, [setUser]);
}

async function loginWithGoogle() {
    try {
        const provider = new GoogleAuthProvider();
        const auth = getAuth();

        const { user } = await signInWithPopup(auth, provider);

        return { uid: user.uid, displayName: user.displayName, photoURL: user.photoURL };
    } catch (error) {
        if (error.code !== 'auth/cancelled-popup-request') {
            console.error(error);
        }

        return null;
    }
}

async function logOutFromGoogle() {
    try {
        const auth = getAuth();

        await auth.signOut();
    } catch (error) {
        console.error(error);
    }
}

export { loginWithGoogle, logOutFromGoogle, SubscribeUserAuthEvent };