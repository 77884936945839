import React from 'react';
import { loginWithGoogle, logOutFromGoogle, SubscribeUserAuthEvent } from '../services/firebase';

const AuthContext = React.createContext();

const AuthProvider = (props) => {
    const [user, setUser] = React.useState(null);

    SubscribeUserAuthEvent(setUser);
    
    const login = async () => {
        const user = await loginWithGoogle();
        if (!user) {
            // TODO: Handle failed login
        }

        setUser(user);
    };

    const logout = async () => {
        await logOutFromGoogle();
        setUser(null);
    };

    const value = { user, login, logout };

    return <AuthContext.Provider value={value} {...props} />;
};


export { AuthContext, AuthProvider };