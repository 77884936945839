export const url = process.env.REACT_APP_API_URL; //"https://localhost:8000"//"https://api.mcapn.com";

export const GetLinkStatus = async (idToken) => {
    const response = await fetch(url + '/UserChannel/linkstatus', {
        method: 'GET',
        headers: {
        'Content-Type': 'application/json',
        'AccessToken' : idToken
        }
    })
    
    const myJson = await response.json(); //extract JSON from the http response
    // do something with myJson

    var status = response.status;
    return {status, myJson};
}

export const LinkGoogle = async (idToken) => {
    const response = await fetch(url + '/UserChannel/LinkGoogle', {
        method: 'POST',
        headers: {
        'Content-Type': 'application/json',
        'AccessToken' : idToken
        }
    })

    const myJson = await response.json(); //extract JSON from the http response
    // do something with myJson

    var status = response.status;
    return {status, myJson};
}

export const UnlinkGoogle = async (idToken) => {
    const response = await fetch(url + '/UserChannel/UnlinkGoogle', {
        method: 'POST',
        headers: {
        'Content-Type': 'application/json',
        'AccessToken' : idToken
        }
    })

    const myJson = await response.json(); //extract JSON from the http response
    // do something with myJson

    var status = response.status;
    return {status, myJson};
} 