import React, { useEffect, useState } from "react";
import "./SocialMedia.css";

import { GetLinkStatus, LinkGoogle, UnlinkGoogle } from "../../Client/ManagementServiceClient.js";
import { getAuth } from "firebase/auth";

function SocialMedia() {
    const [accounts, setAccounts] = useState([

    ]);

    useEffect(() => {
        async function fetchLinkStatus() {
            try {
                const auth = getAuth();
                const user = auth.currentUser;
                if (user) {
                    const idToken = await user.getIdToken();
                    const { status, myJson } = await GetLinkStatus(idToken);
                    if (status === 200) {
                        const updatedAccounts = [];
                        const linkedChannels = myJson.LinkedChannels;
                        for (let i = 0; i < linkedChannels.length; i++) {
                            updatedAccounts.push({ name: linkedChannels[i].Channel, connected: linkedChannels[i].Linked });
                        }
                        

                        console.log(updatedAccounts);
                        setAccounts(updatedAccounts);
                    }
                } else {
                    console.error("No user is signed in.");
                }
            } catch (error) {
                console.error("Failed to fetch link status:", error);
            }
        }

        fetchLinkStatus();
    }, []);

    const handleLink = async (accountName) => {
        try {
            const auth = getAuth();
            const user = auth.currentUser;
            if (user) {
                const idToken = await user.getIdToken();
                const { status, myJson } = await LinkGoogle(idToken);
                if (status === 200) {
                    const redirectUrl = myJson.RedirectUrl;
                    if (redirectUrl) {
                        window.location.href = redirectUrl;
                    }
                }
            } else {
                console.error("No user is signed in.");
            }
        } catch (error) {
            console.error(`Failed to link ${accountName} account:`, error);
        }
    };

    const handleUnlink = async (accountName) => {
        try {
            const auth = getAuth();
            const user = auth.currentUser;
            if (user) {
                const idToken = await user.getIdToken();
                const { status } = await UnlinkGoogle(idToken);
                if (status === 200) {
                    // Update the account state to reflect the new connection status
                    setAccounts(accounts.map(account => 
                        account.name === accountName ? { ...account, connected: false } : account
                    ));
                }
            } else {
                console.error("No user is signed in.");
            }
        } catch (error) {
            console.error(`Failed to unlink ${accountName} account:`, error);
        }
    };

    return (
        <div className="social-media-container">
            <h1>Manage Social Media Accounts</h1>
            <p>Connect your social media accounts</p>
            {accounts.map((account) => (
                <div className="social-media-account" key={account.name}>
                    <div className="account-info">
                        <h2>{account.name}</h2>
                        <p className={`status ${account.connected ? "connected" : "not-connected"}`}>
                            <span className="status-indicator"></span>
                            {account.connected ? "Connected" : "Not Connected"}
                        </p>
                    </div>
                    <div className="account-actions">
                        {account.connected ? (
                            <button onClick={() => handleUnlink(account.name)}>Unlink {account.name} Account</button>
                        ) : (
                            <button onClick={() => handleLink(account.name)}>Link {account.name} Account</button>
                        )}
                    </div>
                </div>
            ))}
        </div>
    );
}

export { SocialMedia };