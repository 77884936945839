import React, { useState } from 'react';
import './styles.css';

function VideoUploader() {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [setThumbnail] = useState(null);
    const [ setVideo] = useState(null);
    const [hashtags, setHashtags] = useState('');
    const [uploadDateTime, setUploadDateTime] = useState('');

    const handleThumbnailChange = (e) => {
        setThumbnail(e.target.files[0]);
    };

    const handleVideoChange = (e) => {
        setVideo(e.target.files[0]);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission logic
    };

    return (
        <div className="video-uploader">
            <h1>Upload a Video</h1>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="title">Title</label>
                    <input
                        type="text"
                        id="title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="description">Description</label>
                    <textarea
                        id="description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="thumbnail">Thumbnail</label>
                    <input
                        type="file"
                        id="thumbnail"
                        accept="image/*"
                        onChange={handleThumbnailChange}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="video">Video</label>
                    <input
                        type="file"
                        id="video"
                        accept="video/*"
                        onChange={handleVideoChange}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="hashtags">Hashtags</label>
                    <input
                        type="text"
                        id="hashtags"
                        value={hashtags}
                        onChange={(e) => setHashtags(e.target.value)}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="uploadDateTime">When do you want to upload this? (Leave empty for now)</label>
                    <input
                        type="datetime-local"
                        id="uploadDateTime"
                        value={uploadDateTime}
                        onChange={(e) => setUploadDateTime(e.target.value)}
                    />
                </div>
                <button type="submit">Upload</button>
            </form>
        </div>
    );
}

export { VideoUploader };