import './styles.css';
import { Link, Outlet } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';

function Landing() {
    const { user, logout } = useAuth();

    return (
        <div className="container">
            <nav>
                <div>
                    <Link to="/">Reports</Link> {" | "}
                    <Link to="VideoUploader">Upload a video</Link> {" | "}
                    <Link to="Schedule">Schedule</Link> {" | "}
                    <Link to="Variants">Variants</Link> {" | "}
                    <Link to="TrendSetters">Trend Setters</Link> {" | "}
                    <Link to="SocialMedia">Social Media Accounts</Link>
                </div>
                {user && (
                    <div className="profile">
                        <img src={user.photoURL} alt="profile" />
                        <label>{user.displayName}</label>
                        <button onClick={logout}>Logout</button>
                    </div>
                )}
            </nav>
            <Outlet />
        </div>
    );
}

export { Landing };